
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { ISession } from "@/schemas/ISession";
import { mapState } from "vuex";

@Component({
  name: "OverviewPage",
  computed: {
    ...mapState("session", ["session"]),
  },
})
export default class OverviewPage extends Vue {
  // The current session identifier
  @Prop({ required: true, type: String })
  sessionId!: string;
  // The current session
  session: ISession | undefined;

  created(): void {
    if (!this.session) {
      this.$store.dispatch("session/fetchSession", {
        sessionId: this.sessionId,
      });
    }
  }

  redirectToindividualSession(): void {
    this.$router.push({
      name: "taskDetail",
      params: {
        sessionId: this.sessionId,
      },
    });
  }
}
